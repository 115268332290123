<template>
	<main class="mt-0 transition-all duration-200 ease-in-out ps spectrio-font">
		<section>
			<div
				class="relative flex items-center min-h-screen p-0 overflow-hidden leather-gradient-background-all-white">
				<div class="container z-1">
					<div class="flex flex-wrap -mx-3">
						<div
							class="flex flex-col w-full max-w-full p-6 mx-auto dash-auth-card-foundation pb-7 rounded-2xl lg:mx-0 shrink-0 md:flex-0 md:w-7/12 lg:w-5/12 xl:w-4/12">
							<!--Avatar-->
							<slot name="avatar" />
							<div
								class="relative flex flex-col min-w-0 break-words dash-auth-card dash-auth-shadow lg:py4 dark:bg-slate-850 rounded-2xl">
								<div
									class="flex flex-col justify-start dash-auth-header rounded-t-2xl bg-spectrio-purple">
									<!--Logo-->
									<div class="p-0 mb-0 dash-auth-logo-box">
										<slot name="logo" />
									</div>
									<!--Header-->
									<div class="w-4/5 p-3 pb-4 mb-0 dash-auth-header-box">
										<slot name="header" />
									</div>
									<!--Status-->
									<div v-if="hasStatus" class="mb-0 dash-auth-greeting-box dash-auth-status-box">
										<div class="text-sm">
											<div class="flex items-center justify-center space-x-2 align-middle">
												<p class="mb-0 text-center text-white">
													<!--The demo is reset in every day and some features have been
													restricted for security-->
													<slot name="status" />
												</p>
											</div>
										</div>
									</div>
								</div>
								<!--Subheader-->
								<div v-if="hasSubheader" class="p-6 px-1 py-3 text-center dash-auth-subheader sm:px-6">
									<slot name="subheader" />
								</div>
								<!--Main-Slot-->
								<slot />
								<div v-if="hasSubfooter" class="p-6 px-1 py-3 text-center dash-auth-subfooter sm:px-6">
									<slot name="subfooter" />
								</div>
								<div
									v-if="hasFooter"
									class="p-6 px-1 py-3 text-center dash-auth-footer sm:px-6 rounded-b-2xl">
									<slot name="footer" />
								</div>
							</div>
						</div>
						<div
							class="absolute top-0 right-0 flex-col justify-center hidden w-6/12 h-full max-w-full px-3 pr-0 my-auto text-center flex-0 lg:flex">
							<div
								class="relative flex flex-col justify-center h-full px-24 m-4 bg-cover rounded-xl authentication-image-cover">
								<span
									class="absolute top-0 left-0 w-full h-full bg-cover rounded-xl bg-spectrio-transparent"></span>
								<div class="relative z-30">
									<div class="relative mt-5">
										<img class="login-cover-primary-image" :src="logo.spectrio" />
									</div>
									<p class="text-white login-cover-sub-text spectrio-header-font">Insights Dashboard</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<div class="ps__rail-x" style="left: 0px; bottom: 0px">
			<div class="ps__thumb-x" tabindex="0" style="left: 0px; width: 0px"></div>
		</div>
		<div class="ps__rail-y" style="top: 0px; right: 0px">
			<div class="ps__thumb-y" tabindex="0" style="top: 0px; height: 0px"></div>
		</div>
	</main>
</template>

<script>
import { defineComponent, computed, useSlots, onMounted, ref } from 'vue';
import SpectrioLogo from '@/Components/Images/Logos/spectrio_logo_white_xl.png';
import ABNLogo from '@/Components/Images/Logos/abn_logo_md.png';

export default defineComponent({
	props: {
		backgroundImage: {
			type: String,
			default: 'spectrio-circles-tile.jpg',
		},
		backgroundSize: {
			type: String,
			default: 'cover',
		},
	},
	setup(props, { slots }) {
		const logo = {
			abn: ABNLogo,
			spectrio: SpectrioLogo,
		};
		const publicPath = window.location.origin;

		const statusText = slots.status
			? slots
					.status()
					.map((el) => el.children)
					.join('')
			: '';
		console.log('statusText Len(' + statusText.length + ')', statusText);

		const subHeaderText = slots.subheader
			? slots
					.subheader()
					.map((el) => el.children)
					.join('')
			: '';
		console.log('subHeaderText Len(' + subHeaderText.length + ')', subHeaderText);

		const subFooterText = slots.subfooter
			? slots
					.subfooter()
					.map((el) => el.children)
					.join('')
			: '';
		console.log('subFooterText Len(' + subFooterText.length + ')', subFooterText);

		const footerText = slots.footer
			? slots
					.footer()
					.map((el) => el.children)
					.join('')
			: '';
		console.log('footerText Len(' + footerText.length + ')', footerText);

		const hasStatus = computed(() => {
			return slots.status && slots.status().length > 0 && statusText.length > 0;
		});

		const hasSubheader = computed(() => {
			return slots.subheader && slots.subheader().length > 0 && subHeaderText.length > 0;
		});

		const hasSubfooter = computed(() => {
			return slots.subfooter && slots.subfooter().length > 0 && subFooterText.length > 0;
		});

		const hasFooter = computed(() => {
			return slots.footer && slots.footer().length > 0 && footerText.length > 0;
		});

		const builtImageURL = computed(() => {
			return 'url(' + publicPath + '/assets/img/backgrounds/' + props.backgroundImage + ')';
		})

		console.log('props', props);
		console.log('slots', slots);
		console.log('logo obj:', logo);
		console.log('publicPath: ' + publicPath);

		return {
			builtImageURL,
			hasStatus,
			hasSubheader,
			hasSubfooter,
			hasFooter,
			logo,
			publicPath,
		};
	},
});
</script>

<style scoped>
.authentication-image-cover,
.dash-auth-header {
	background-image: v-bind(builtImageURL);
	background-size: v-bind(backgroundSize);
	background-repeat: repeat;
}
</style>
